var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-alert',{staticClass:"alert alert-custom alert-white alert-shadow fade show gutter-b",attrs:{"show":"","variant":"light"}},[_c('div',{staticClass:"alert-icon"},[_c('span',{staticClass:"svg-icon svg-icon-lg"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Tools/Compass.svg"}})],1)]),_c('div',{staticClass:"alert-text"},[_vm._v("Daftar "),_c('b',[_vm._v("Admin TU Prodi")])]),_c('b-button',{attrs:{"squared":"","variant":"primary"},on:{"click":function($event){return _vm.$router.push('/masters/users/admin-tu-prodi/add')}}},[_vm._v("Tambah")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row justify-content-end mt-n3"},[_c('div',{staticClass:"col-md-5"},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-button',{attrs:{"variant":"success","squared":"","size":"sm"},on:{"click":_vm.search}},[_vm._v(" Cari ")]),_c('b-button',{attrs:{"variant":"danger","squared":"","size":"sm"},on:{"click":_vm.reset}},[_vm._v(" Reset ")])]},proxy:true}])},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Cari Nama"},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1)]),_c('b-table',{staticClass:"mt-3",attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
                    width: field.key === 'action' ? '25%' : '',
                  })})})}},{key:"cell(photo)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-start align-items-center image-container"},[_c('div',{staticClass:"image-input-wrapper",staticStyle:{"position":"relative"}},[_c('img',{staticClass:"image",attrs:{"src":data.item.photo}})])])]}},{key:"cell(action)",fn:function(data){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-circle btn-outline-info",attrs:{"size":"sm","title":"Detail"},on:{"click":function($event){return _vm.$router.push({
                      path:
                        '/masters/users/admin-tu-prodi/detail/' + data.item.id,
                    })}}},[_c('i',{staticClass:"fas fa-eye px-0"})]),(_vm.btnAccess)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-circle btn-outline-success",attrs:{"size":"sm","title":"Edit"},on:{"click":function($event){return _vm.$router.push({
                      path:
                        '/masters/users/admin-tu-prodi/edit/' + data.item.id,
                    })}}},[_c('i',{staticClass:"fas fa-edit px-0"})]):_vm._e(),(_vm.btnAccess)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-circle btn-outline-danger mr-1",attrs:{"size":"sm","title":"Hapus"},on:{"click":function($event){return _vm.deleteData(data.item.id)}}},[_c('i',{staticClass:"fas fa-trash px-0"})]):_vm._e(),(_vm.btnAccess)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-circle",attrs:{"size":"sm","variant":"outline-primary","title":"Reset Password"},on:{"click":function($event){return _vm.resetPassword(data.item.id)}}},[_c('i',{staticClass:"fas fa-redo px-0"})]):_vm._e()]}}])})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }